import { useSWRConfig } from "swr";

import { OrderItem } from "@efarmz/efarmz-domain-typescript";

import useSegment from "../useSegment";
import useSubscription from "./useSubscription";

import formatOrderItem from "@/utils/shared/segment/formatOrderItem";

const useUpdateSubscriptionItems = () => {
  const subscription = useSubscription();
  const segment = useSegment();

  const { mutate } = useSWRConfig();

  const mutateKey = {
    url: `/api/subscriptions/${subscription?.id}`,
  };

  const updateSubscriptionItems = (orderItems: OrderItem[]) => {
    const items = subscription?.items?.length
      ? [
          ...subscription.items.filter((orderItem) => {
            return !orderItems
              .map((item) => item?.item?.id)
              .includes(orderItem?.item?.id);
          }),
        ]
      : [];

    orderItems.forEach((orderItem) => {
      if (!orderItem?.item) {
        return;
      }
      const existingItem = subscription?.items?.find((item) => {
        return item?.item?.id === orderItem?.item?.id;
      });

      const index = subscription?.items?.findIndex((item) => {
        return item?.item?.id === orderItem?.item?.id;
      });

      if (existingItem && index != undefined) {
        const newQty = (existingItem.qty || 0) + (orderItem.qty || 0);
        const newPrice = (existingItem?.item?.finalPrice || 0) * newQty;

        if (newQty > 0 && index > -1) {
          segment.track(`SubscriptionItemUpdated`, {
            old: formatOrderItem(existingItem),
            new: formatOrderItem({
              ...existingItem,
              qty: newQty,
            }),
          });

          items.splice(index, 0, {
            ...existingItem,
            qty: newQty,
          });
        } else {
          segment.track(`SubscriptionItemRemoved`, {
            ...formatOrderItem({
              ...orderItem,
            }),
          });
        }
      } else {
        const { createdAt, ...other } = orderItem;
        segment.track(`SubscriptionItemAdded`, {
          ...formatOrderItem({
            ...other,
            createdAt: createdAt || new Date(),
          }),
        });
        items.push({
          ...other,
          createdAt: createdAt || new Date(),
        });
      }
    });

    mutate(
      mutateKey,
      (state) => {
        return {
          ...state,
          data: {
            ...subscription,
            items,
          },
        };
      },
      {
        revalidate: false,
      }
    );
  };

  return updateSubscriptionItems;
};

export default useUpdateSubscriptionItems;
